/* @import url(https://allfont.net/allfont.css?fonts=franklin-gothic-demi); */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Franklin Gothic Demi';
  font-style: normal;
  font-weight: 400;
  src: local('Franklin Gothic Demi'), local('FranklinGothicDemi-Regular'),
    url(https://allfont.ru/cache/fonts/franklin-gothic-demi_b464fa88f7e20fc1adee9c23a3ff6bd0.woff)
      format('woff'),
    url(https://allfont.ru/cache/fonts/franklin-gothic-demi_b464fa88f7e20fc1adee9c23a3ff6bd0.ttf)
      format('truetype');
}

@font-face {
  font-family: 'Screener W01 Regular';
  src: url('./fonts/Screener/@font-face/ed8b4476ce0cbdba6869859c2fd4ee44.eot'); /* IE9*/
  src: url('./fonts/Screener/@font-face/ed8b4476ce0cbdba6869859c2fd4ee44.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('./fonts/Screener/@font-face/ed8b4476ce0cbdba6869859c2fd4ee44.woff2')
      format('woff2'),
    /* chrome、firefox */
      url('./fonts/Screener/@font-face/ed8b4476ce0cbdba6869859c2fd4ee44.woff')
      format('woff'),
    /* chrome、firefox */
      url('./fonts/Screener/@font-face/ed8b4476ce0cbdba6869859c2fd4ee44.ttf')
      format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('./fonts/Screener/@font-face/ed8b4476ce0cbdba6869859c2fd4ee44.svg#Screener W01 Regular')
      format('svg'); /* iOS 4.1- */
}

@font-face {
  font-family: 'ScreenerW01-Bold';
  src: url('//db.onlinewebfonts.com/t/9673fc535dfa5d537b6bdb94c0375ed7.eot');
  src: url('//db.onlinewebfonts.com/t/9673fc535dfa5d537b6bdb94c0375ed7.eot?#iefix')
      format('embedded-opentype'),
    url('//db.onlinewebfonts.com/t/9673fc535dfa5d537b6bdb94c0375ed7.woff2')
      format('woff2'),
    url('//db.onlinewebfonts.com/t/9673fc535dfa5d537b6bdb94c0375ed7.woff')
      format('woff'),
    url('//db.onlinewebfonts.com/t/9673fc535dfa5d537b6bdb94c0375ed7.ttf')
      format('truetype'),
    url('//db.onlinewebfonts.com/t/9673fc535dfa5d537b6bdb94c0375ed7.svg#ScreenerW01-Bold')
      format('svg');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .App {
    padding: 0;
    background-color: black;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Franklin Gothic Demi', -apple-system, BlinkMacSystemFont,
      'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    /* font-family: "Franklin Gothic Demi", arial !important; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    position: relative;
  }

  .headerLink {
    @apply text-black uppercase text-2xl md:text-base  transition-all duration-300 hover:text-primary-200;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2px !important;
  }

  .backShadow::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 110%;
    height: 120%;
    filter: blur(58px);
    background: #bc00ff26;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  .blur {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000000cc;
  }

  .splashScreen img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* Modal */
  .modalOverlay {
    position: relative;
  }

  .modalOverlay::before {
    content: '';
    position: absolute;
    /* background-color: black; */
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    height: 100%;
    /* opacity: 75%; */
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: saturate(40%) blur(4px);
  }

  .pressComponent {
    box-shadow: 4px 2px 10px 4px rgba(0, 0, 0, 0.12);
  }

  /* Swiper  */

  .swiper-3d {
    perspective: 820px !important;
  }

  /* Animation  */

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      display: none;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      display: none;
    }
  }

  @keyframes slideUp {
    to {
      -webkit-transform: translateX(-88%);
      transform: translateY(-108%);
      display: none;
    }
  }

  @keyframes slideDown {
    to {
      -webkit-transform: translateX(-88%);
      transform: translateY(100%);
      display: none;
    }
  }

  .slideOutDownx {
    -webkit-animation-name: slideOutDownx;
    animation-name: slideOutDownx;
    animation-fill-mode: forwards;
  }

  @-webkit-keyframes slideOutDownx {
    from {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    to {
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  }

  @keyframes slideOutDownx {
    from {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    to {
      visibility: hidden;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  }

  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }

  .slideUp {
    -webkit-animation-name: slideUp;
    animation-name: slideUp;
  }

  .slideDown {
    -webkit-animation-name: slideDown;
    animation-name: slideDown;
  }

  .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }

  .animated {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .animation-delay3000 {
    animation-delay: 3s;
  }

  .animation-delay5000 {
    animation-delay: 5s;
  }

  .duration-5000 {
    transition-duration: 5000ms;
  }

  .animated2 {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .animated5 {
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .audioComponent::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgb(0 0 0 / 80%); */
    z-index: -1;
    -webkit-backdrop-filter: saturate(220%) blur(74px);
    backdrop-filter: saturate(40%) blur(5px);
  }

  /* Slick Slider  */

  .slick-track .slick-current div div div img {
    scale: 1.1;
    padding: 0;
  }

  .slick-track .slick-current div div div {
    width: 100% !important;
    height: 100% !important;
  }

  .slick-slider > button.slick-next::before {
    color: red !important;
  }

  .slick-prev,
  .slick-next {
    width: 30px !important;
    height: 30px !important;
    border: 1px solid white !important;
    border-radius: 50% !important;
  }
  .slick-prev:before,
  .slick-next:before {
    color: #bb8a2e !important;
    font-size: 30px !important;
    position: relative;
    left: -1px;
  }
}
