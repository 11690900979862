.icon {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 150px;
  overflow: hidden;
  border-radius: 3px;
  height: 30px;
}

.icon span {
  margin-top: -2px;
  height: 8px;
  background-color: black;
  content: "";
}

.icon span:nth-child(1) {
  width: 50px;
}

.icon span:nth-child(2) {
  width: 20px;
}

.icon span:nth-child(3) {
  width: 15px;
}

.icon span:nth-child(4) {
  width: 15px;
}
.icon span:nth-child(5) {
  width: 50px;
}

@keyframes bounce {
  0% {
    height: 2px; /* start by scaling to 30% */
  }

  100% {
    height: 100%;
  }
}
